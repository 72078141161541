import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const SubMenu = ({ item }) => {
  const [subNav, setSubNav] = useState(false);

  return item.subOptions ? (
    <li className={classNames({ opened: subNav, withSub: item?.subOptions })}>
      <span onClick={() => setSubNav(!subNav)}>{item.title}</span>
      <div className="submenu">
        <ul className="submenu-content">
          {item.subOptions?.map(({ title, id, path }) => (
            <Link to={path} key={id}>
              <span>{title}</span>
            </Link>
          ))}
        </ul>
      </div>
    </li>
  ) : (
    <li>
      <Link to={item.path}>{item.title}</Link>
    </li>
  );
};

export default SubMenu;
