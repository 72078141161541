const menuData = [
  {
    title: 'Home',
    path: '/',
    id: 1,
  },
  {
    title: 'Filling products',
    id: 2,
    subOptions: [
      {
        title: 'Product range',
        path: '/product-range',
        id: 3,
      },
      {
        title: 'Coffee',
        path: '/coffee',
        id: 4,
      },
    ],
  },
  {
    title: 'Coffee Cup',
    id: 5,
    subOptions: [
      {
        title: 'Vending',
        path: '/vending',
        id: 6,
      },
      {
        title: 'To/go',
        path: '/to-go',
        id: 7,
      },
    ],
  },
  {
    title: 'Development',
    id: 8,
    path: '/development',
  },
  {
    title: 'Contact',
    id: 9,
    path: '/contact',
  },
];

export default menuData;
