import { memo } from 'react';
import PropTypes from 'prop-types';

const Button = ({ text, isLoading, onClick, ...rest }) => {
  return (
    <button onClick={onClick} className="karl" {...rest}>
      {isLoading ? 'Loading...' : <div className="karl">{text}</div>}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default memo(Button);
