import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  type,
  name,
  value,
  label,
  error,
  className,
  placeholder,
  handleChange,
  ...rest
}) => {
  const { hasError, message } = error || {};

  return (
    <div className="input-parent">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        id={name}
        name={name}
        value={value}
        spellCheck="false"
        onChange={handleChange}
        placeholder={placeholder}
        type={type ? 'text' : 'password'}
        className={classNames({ danger: hasError })}
        {...rest}
      />
      {hasError && (
        <div className="errors-block">
          <div className="input-message error">{message}</div>
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  error: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default memo(Input);
