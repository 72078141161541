import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/form/fields';
import useFormValidations from 'components/form/validations/useFormValidations';

const Form = ({ children, onSubmit, rules, submitButtonProps: { text } }) => {
  const [messages, validate] = useFormValidations(rules);

  const handleChange = event => {
    const { value, name } = event.target;

    rules?.[name] && validate({ value, name });
  };

  const submit = event => {
    event.preventDefault();

    if (!rules) return;

    const errors = Children.toArray(children).filter(item => {
      const { name, value } = item.props;

      return rules[name] ? validate({ value, name }) : {};
    });

    if (errors.length) return;

    onSubmit();
  };

  return (
    <form onSubmit={submit} onChange={handleChange}>
      {Children.map(children, child =>
        cloneElement(child, { onBlur: handleChange, error: messages[child.props.name] }),
      )}
      <Button text={text} />
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.object,
  rules: PropTypes.object,
};

export default Form;
