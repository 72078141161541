import { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage } from 'store/selectors';

const TranslatorContext = createContext();

const TranslatorProvider = ({ children }) => {
  const language = useSelector(getLanguage);

  const [translations, setTranslations] = useState({});

  const changeLanguage = useCallback(async lang => {
    const response = await fetch(`/translations/${lang}.json`);
    const result = await response.json();

    setTranslations({ [lang]: result || {} });
  }, []);

  useEffect(() => {
    changeLanguage(language);
  }, [changeLanguage, language]);

  const translate = useCallback(
    (str, ...args) => {
      const currentTranslations = translations[language];
      const translation = currentTranslations?.[str] || str;

      return !args.length ? translation : translation.replace(/__param__/g, () => args.shift());
    },
    [translations, language],
  );

  const value = useMemo(() => ({ translate }), [translate]);

  return <TranslatorContext.Provider value={value}>{children}</TranslatorContext.Provider>;
};

const useTranslator = () => {
  const { translate: t } = useContext(TranslatorContext);

  return { t };
};

export { useTranslator, TranslatorProvider };
