import { Route, Switch } from 'react-router-dom';

import mainRoutes from 'config/routes';
import Header from 'sections/header';
import Footer from 'sections/footer';

import 'assets/scss/style.scss';
import './style.scss';

const Layout = () => {
  return (
    <>
      <Header />
      <div className="content-block">
        <main>
          <Switch>
            {mainRoutes.map(({ path, exact, component }) => (
              <Route key={path} path={path} exact={exact} component={component} />
            ))}
          </Switch>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
