import { useTranslator } from 'helpers/translator';
import { Link } from 'react-router-dom';

import Logo from 'assets/images/logo.svg';
import ContactUs from 'sections/contactUs';

import './style.scss';

const Footer = () => {
  const { t } = useTranslator();

  return (
    <>
      <ContactUs />
      <footer>
        <img src={Logo} alt="Footer logo" className="footer-logo" />
        <div className="blocks">
          <div className="column">
            <span className="title">{t('Address')}</span>
            <div className="info">
              <p>
                {t('Walgaustraße')}
                <br />
                {t('48A-6719 Bludesch')}
              </p>
              <p>
                <b>{t('Tel:')} </b>
                {t('+43 (0) 5525 63355')}
              </p>
              <p>
                <b>{t('Fax:')} </b>
                {t('+43 (0) 5525 63355 30')}
              </p>
            </div>
          </div>
          <div className="column">
            <span className="title">{t('Opening times')}</span>
            <div className="info">
              <p>
                <b>{t('Monday to Friday')}</b>
                <br />
                {t('7:15 a.m. - 12:00 p.m.')}
                <br />
                {t('1:00 p.m. - 5:00 p.m.')}
              </p>
              <p>
                <b>{t('closed on public holidays')} </b>
              </p>
            </div>
          </div>
          <div className="column">
            <span className="title">{t('More info')}</span>
            <Link to="/">Link 1</Link>
            <Link to="/">Link 2</Link>
            <Link to="/">Link 3</Link>
            <Link to="/">Link 4</Link>
          </div>
        </div>
        <div className="copyright">© Almiwelt 2020</div>
      </footer>
    </>
  );
};

export default Footer;
