import { lazy } from 'react';

import withSuspense from 'helpers/withSuspense';

const mainRoutes = [
  {
    path: '/',
    exact: true,
    component: withSuspense(lazy(() => import('pages/home'))),
  },
  {
    path: '/product-range',
    exact: true,
    component: withSuspense(lazy(() => import('pages/productRange'))),
  },
  {
    path: '/product-range/:productId',
    exact: true,
    component: withSuspense(lazy(() => import('pages/productRangeItem'))),
  },
  {
    path: '/coffee',
    exact: true,
    component: withSuspense(lazy(() => import('pages/coffee'))),
  },
  {
    path: '/coffee/:productId',
    exact: true,
    component: withSuspense(lazy(() => import('pages/coffeeItem'))),
  },
  {
    path: '/vending',
    exact: true,
    component: withSuspense(lazy(() => import('pages/vending'))),
  },
  {
    path: '/vending/:productId',
    exact: true,
    component: withSuspense(lazy(() => import('pages/vendingItem'))),
  },
  {
    path: '/to-go',
    exact: true,
    component: withSuspense(lazy(() => import('pages/toGo'))),
  },
  {
    path: '/to-go/:productId',
    exact: true,
    component: withSuspense(lazy(() => import('pages/toGoItem'))),
  },
  {
    path: '/development',
    exact: true,
    component: withSuspense(lazy(() => import('pages/development'))),
  },
  {
    path: '/contact',
    exact: true,
    component: withSuspense(lazy(() => import('pages/contact'))),
  },
  {
    path: '*',
    component: withSuspense(lazy(() => import('pages/notFound'))),
  },
];

export default mainRoutes;
