import { useState, useCallback } from 'react';

import { validateForm } from 'components/form/validations/validate';

const useFormValidations = validationRules => {
  const [errors, setErrors] = useState({});

  const validate = useCallback(
    ({ value, name }) => {
      const error = validateForm(value, validationRules[name]);

      setErrors(prevState => ({
        ...prevState,
        [name]: error,
      }));

      return error;
    },
    [validationRules],
  );

  return [errors, validate];
};

export default useFormValidations;
