import ContactUsForm from './ContactUsForm';

import './style.scss';

const ContactUs = () => {
  return (
    <div className="contact-us-parent">
      <h2>Contact Us</h2>
      <p>Would you like to make an appointment with us? We look forward to every message!</p>
      <ContactUsForm />
    </div>
  );
};

export default ContactUs;
