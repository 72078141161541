import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from 'store';
import { TranslatorProvider } from 'helpers/translator';
import Layout from 'sections/layout';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <TranslatorProvider>
          <Layout />
        </TranslatorProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
