export const validateForm = (value, rules) => {
  return Object.keys(rules).reduce((acc, rule) => {
    if (Object.keys(acc).length) return acc;

    if (
      (rule === 'required' && rules[rule].value && !value) ||
      (rule === 'pattern' && !new RegExp(rules[rule].regexp).test(value))
    ) {
      return {
        hasError: true,
        message: rules[rule].message,
      };
    }

    return acc;
  }, {});
};
