import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import useMount from 'helpers/hooks/useMount';
import { setLanguage } from 'store/actions';
import { getLanguage } from 'store/selectors';
import Navigation from 'sections/navigation';

import LogoWhite from 'assets/images/logo.svg';

import './style.scss';

const languages = {
  en: {
    shortKey: 'en',
    longWord: 'English',
  },
  de: {
    shortKey: 'de',
    longWord: 'Deutsche',
  },
};

const Header = () => {
  const dispatch = useDispatch();

  const language = useSelector(getLanguage);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const changeLanguages = useCallback(
    language => {
      return () => {
        dispatch(setLanguage(language));
      };
    },
    [dispatch],
  );

  useMount(() => {
    const handleScroll = () => setIsScrolled(window.pageYOffset > 80);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <header className={classNames({ small: isScrolled })}>
      <div className="header-block">
        <Link to="/" className="logo">
          <img src={LogoWhite} alt="logo" className="white" />
        </Link>
        <i
          className={classNames('icon-menu', { opened: isMenuOpened })}
          onClick={() => setIsMenuOpened(!isMenuOpened)}
        />
        <ul className={classNames('navigation', { opened: isMenuOpened })}>
          <Navigation />
          <li className="language">
            <div className="current">
              <i className={classNames('flag', languages[language]?.shortKey)} />
            </div>
            <div className="submenu">
              <ul className="submenu-content">
                {Object.keys(languages).map(languageKey => {
                  const { shortKey, longWord } = languages[languageKey];

                  return (
                    <li
                      key={shortKey}
                      className={classNames({ active: shortKey === language })}
                      onClick={changeLanguages(shortKey)}
                    >
                      {longWord}
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
