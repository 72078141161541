import Form from 'components/form';
import { Input, Textarea } from 'components/form/fields';
import { useState } from 'react';

const validationRules = {
  companyName: {
    required: {
      value: true,
      message: 'This field is required',
    },
  },
  userName: {
    required: {
      value: true,
      message: 'This field is required',
    },
  },
  email: {
    required: {
      value: true,
      message: 'This field is required',
    },
    pattern: {
      regexp:
        '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+([.])[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$',
      message: 'Invalid email address',
    },
  },
  address: {
    required: {
      value: true,
      message: 'This field is required',
    },
  },
  message: {
    required: {
      value: true,
      message: 'This field is required',
    },
  },
};

const initialValues = {
  companyName: '',
  userName: '',
  email: '',
  address: '',
  message: '',
};

const ContactUsForm = () => {
  const [companyName, setCompanyName] = useState(initialValues.companyName);
  const [userName, setUserName] = useState(initialValues.userName);
  const [email, setEmail] = useState(initialValues.email);
  const [address, setAddress] = useState(initialValues.address);
  const [message, setMessage] = useState(initialValues.message);

  const handleSend = () => console.log('555');

  const handleCompanyNameChange = event => {
    setCompanyName(event.target.value);
  };

  const handleUserNameChange = event => {
    setUserName(event.target.value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleAddressChange = event => {
    setAddress(event.target.value);
  };

  const handleMessageChange = event => {
    setMessage(event.target.value);
  };

  return (
    <Form
      onSubmit={handleSend}
      submitButtonProps={{ text: 'Send message ' }}
      rules={validationRules}
    >
      <div className="two-input">
        <Input
          type="text"
          name="userName"
          value={userName}
          label="First Name/Last Name"
          handleChange={handleUserNameChange}
        />
        <Input
          type="text"
          name="address"
          value={address}
          label="City/Index/Address"
          handleChange={handleAddressChange}
        />
      </div>
      <div className="two-input">
        <Input
          type="text"
          name="companyName"
          value={companyName}
          label="Company Name"
          handleChange={handleCompanyNameChange}
        />
        <Input
          type="text"
          name="email"
          value={email}
          label="Email"
          handleChange={handleEmailChange}
        />
      </div>
      <Textarea name="message" value={message} label="Message" handleChange={handleMessageChange} />
    </Form>
  );
};

export default ContactUsForm;
