import { createSlice } from '@reduxjs/toolkit';

import { getFromLocalStorage, saveToLocalStorage } from 'helpers/persisit';

const initialState = {
  language: getFromLocalStorage('language') || 'de',
};

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;

      saveToLocalStorage('language', action.payload);
    },
  },
});

const { actions, reducer } = uiStateSlice;

export const { setLanguage } = actions;
export default reducer;
